import { render, staticRenderFns } from "./UtilityEmptyDefaultLayout.vue?vue&type=template&id=54fe947c&scoped=true"
import script from "./UtilityEmptyDefaultLayout.vue?vue&type=script&lang=js"
export * from "./UtilityEmptyDefaultLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54fe947c",
  null
  
)

export default component.exports